.container {
    background-color: #1e1e1e;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-content {
    max-width: 500px;
    display: flex;
}

.side-content {
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
}

.banner-img {
    width: 100%;
    height: 150px;
}

.title {
    font-size: 41.2px;
    color: white;
    text-align: center;
    margin: 20px 0;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    .name-input {
        font-size: 14px;
        color: rgb(129, 137, 150);
        &:before {
            color: #ff4d4f;
            content: '*';
            display: inline-block;
            font-family: SimSun, sans-serif;
            font-size: 14px;
            line-height: 1;
            margin-right: 4px;
        }
    }

    .form-input {
        color: white;
        border-radius: 5px;
        background-color: #2a313c;
        font-size: 16px;
        padding: 6.5px 11px;
        line-height: 3.2rem;
    }
}
.btn-submit {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    text-align: center;
    z-index: 1000;
}

.block-events {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    background-color: #f6d06a;
    border-radius: 6px;
    border: none;
    flex-grow: 1;
    padding: 4px 15px;
    height: 45px;
    font-weight: 700;
}

.divider {
    text-align: center;
    margin: 16px 0;
    display: block;

    .divider-text {
        font-size: 14px;
        color: rgb(129, 137, 150);
        z-index: 12;
        background-color: red;
        width: 50px;
        height: 50px;
    }

    &:before {
        display: block;
        border-bottom: 0;
        border-top: 1px solid transparent;
        border-top-color: inherit;
        content: '';
        position: relative;
        top: 50%;
        transform: translateY(50%);
        width: 100%;
        z-index: 0;
    }

    // &:after {
    //     display: block;
    //     position: relative;
    //     border-bottom: 0;
    //     border-top: 1px solid transparent;
    //     border-top-color: inherit;
    //     content: '';
    //     top: 50%;
    //     left: 0;
    //     transform: translateY(50%);
    //     width: 45%;
    // }
}

.btn-register {
    display: flex;
    justify-content: center;
    margin: 24px 0;
    text-align: center;
}

.register {
    color: white;
    border: 1px solid #f6d06a;
    background-color: #2a313c;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    flex-grow: 1;
    padding: 4px 15px;
    height: 45px;
    font-weight: 700;

    &:hover {
        background-color: #45aafc;
        border: none;
    }
}

.desc {
    cursor: pointer;
    color: yellow;
}

.term {
    display: flex;
    flex-direction: row;
}

.check-box {
}

.check-box-term {
    transform: scale(1.5);
    cursor: pointer;
    padding: 10px;
}

.desc-term {
    cursor: pointer;
    padding-left: 12px;
    font-size: 14px;
    color: rgb(129, 137, 150);
    user-select: none;
    margin-bottom: 24px;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.desc-footer {
    font-size: 14px;
    font-weight: 600;
    color: #8c8c8c;
    line-height: 1.6rem;
}

.login-btn {
    color: #e1ba3c;
    font-size: 16px;
    padding-left: 4px;
    line-height: 1.6rem;
}

// .header {
//     width: 1200px;
//     height: 84px;
//     margin: auto;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     .header-left {
//         display: flex;
//         align-items: center;
//     }
//     .logo img {
//         width: 12rem;
//         object-fit: cover;
//     }
//     .header-left .header-title {
//         margin-left: 15px;
//         margin-top: 5px;
//         font-size: 23px;
//         letter-spacing: 1px;
//     }
//     .header-helper {
//         cursor: pointer;
//         color: rgb(238, 77, 45);
//     }
// }

// main.container {
//     width: 100%;
//     background-color: rgb(238, 77, 45);
//     .main-content {
//         width: 1040px;
//         height: 600px;
//         min-height: 600px;
//         display: flex;
//         align-items: center;
//         justify-content: flex-end;
//         margin: 0 auto;
//         background-size: cover;
//         background-repeat: no-repeat;
//         background-position: center center;
//         background-color: rgb(238, 77, 45);
//     }

//     .main-content-mobile {
//         // height: 80vh;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         margin: 0 auto;
//         margin-top: 50px;
//         background-size: cover;
//         background-repeat: no-repeat;
//         background-position: center center;
//         background-color: #fff;
//     }
//     .form-content {
//         width: 37rem;
//         min-height: 480px;
//         background-color: #fff;
//         padding: 25px;
//         box-sizing: border-box;
//         box-shadow: 0 3px 10px 0 rgb(0 0 0 / 14%);
//         border-radius: 0.25rem;
//         overflow: hidden;
//     }

//     .form-content-mobile {
//         width: 37rem;
//         min-height: 480px;
//         background-color: #fff;
//         padding: 25px;
//         box-sizing: border-box;
//         box-shadow: transparent;
//         border-radius: 0.25rem;
//         overflow: hidden;
//     }

//     .form-title {
//         font-size: 20px;
//         letter-spacing: 1px;
//     }

//     .form-group {
//         margin-top: 25px;
//         padding: 5px;
//         border-radius: 2px;
//         position: relative;
//         box-shadow: inset 0 2px 0 rgb(0 0 0 / 2%);
//         border: 1px solid rgba(0, 0, 0, 0.14);
//     }

//     .form-group input {
//         width: 100%;
//         padding: 0.75rem;
//         outline: none;
//         border: 1;
//         flex: 1;
//         flex-shrink: 0;
//         filter: none;
//     }

//     .form-group input[type='password'] {
//         padding-right: 30px;
//     }

//     .btn-submit {
//         margin-top: 25px;
//         background-color: #ee4d2d;
//         opacity: 0.7;
//         box-shadow: 0 1px 1px rgb(0 0 0 / 9%);
//     }

//     .btn-submit button {
//         width: 100%;
//         height: 46px;
//         border: 0;
//         font-size: 16px;
//         padding: 0 0.625rem;
//         border-radius: 0.125rem;
//         outline: none;
//         background-color: transparent;
//         color: #fff;
//         -moz-user-select: none !important;
//         -webkit-touch-callout: none !important;
//         -webkit-user-select: none !important;
//         -khtml-user-select: none !important;
//         -moz-user-select: none !important;
//         -ms-user-select: none !important;
//         user-select: none !important;
//         user-select: none !important;
//     }

//     .icon-lock,
//     .icon-lock-open {
//         position: absolute;
//         padding: 10px;
//         top: 4px;
//         right: 1px;
//         cursor: pointer;
//     }
// }

// .forgot-password {
//     margin: 15px 0;
//     text-align: right;
//     a {
//         font-size: 13px;
//         color: #05a;
//     }
// }

// .login-others {
//     .more {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         margin: 15px 0;
//         .or {
//             padding: 0 16px;
//             font-size: 14px;
//             color: #ccc;
//             text-transform: uppercase;
//         }

//         .line {
//             height: 1px;
//             width: 100%;
//             background-color: #dbdbdb;
//             flex: 1;
//         }
//     }

//     .more-account {
//         display: flex;
//         margin-top: 15px;
//         .social-white-fb-blue-png {
//             background-size: 325% 287.5%;
//             background-position: 5.555555555555555% 62.666666666666664%;
//             width: 22px;
//             height: 22px;
//         }
//         .social-white-google-png {
//             width: 22px;
//             height: 22px;
//             background-size: 722.2222222222222% 638.8888888888889%;
//             background-position: 83.92857142857143% 5.154639175257732%;
//         }

//         button:first-child {
//             margin-right: 15px;
//         }

//         button {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             flex: 1;
//             box-sizing: border-box;
//             outline: none;
//             border: 1px solid rgba(0, 0, 0, 0.26);
//             background-color: #fff;
//             color: rgba(0, 0, 0, 0.87);
//             padding: 0 2px;
//             border-radius: 2px;
//             width: 100%;
//             height: 40px;
//             font-size: 16px;
//             .content {
//                 margin-right: 10px;
//             }
//         }
//     }
// }

// .register {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-top: 25px;
//     font-size: 14px;
//     .content {
//         color: rgba(0, 0, 0, 0.26);
//         padding-right: 0.25re;
//         a {
//             font-size: 14px;
//             margin-left: 5px;
//             color: #ee4d2d;
//         }
//     }
// }

// .privacy {
//     margin: 25px 0;
//     .content {
//         text-align: center;
//         font-size: 1.2rem;
//         a {
//             color: #ee4d2d;
//         }
//     }
// }

// .logo-shopee-mobile {
//     margin: auto;
//     height: 50px;
//     width: 50px;
//     color: #ee4d2d;
//     background-repeat: no-repeat;
//     background-size: cover;
// }

// .header-mobile {
//     padding: 15px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     .header-left {
//         font-size: 18px;
//         display: flex;
//         align-items: center;
//         .fa-arrow-left {
//             color: #ee4d2d;
//         }
//         .header-title {
//             margin-left: 15px;
//         }
//     }
// }
