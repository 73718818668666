.navbar__footer {
    background-color: rgb(12, 19, 29);
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar__footer--container {
    width: 360px;
}
