.sc-hUpaCq.fvrbNa img {
    height: 190px;
    width: 360px;
}

.search-order {
    display: flex;
    justify-content: center;
    margin: 1rem 0px;
}

.sc-lcepkR.hJmUWK {
    width: 100%;
    background-color: rgb(219, 151, 0);
    border: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    color: var(--primary-title);
}

.ant-btn-primary {
    background: #1890ff;
    border-color: #1890ff;
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    color: #fff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
}

.ant-btn-lg {
    border-radius: 2px;
    font-size: 16px;
    height: 40px;
    padding: 6.4px 15px;
}

.ant-row.ant-row-space-between {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.ant-row.ant-row-space-between .ant-col {
    display: block;
    flex: 0 0 50%;
    max-width: 176px;
}

.money-trade {
    background-color: gray;
    font-size: 18px;
    color: yellow;
    font-weight: 600;
    padding: 8px 0px;
    text-align: center;
    border-radius: 4px;
    margin: 24px 0px 12px;
}

.kHnHbZ {
    margin-top: 1rem;
    color: rgba(0, 0, 0, 0.65);
}

.sc-ikJyIC.gNkWet {
    width: 176px;
}

.sc-lcepkR.hJmUWK {
    position: relative;
}

.progress-bar {
    margin-top: 10px;
    border-radius: 10px;
    height: 10px;
    max-width: 360px;
    background-color: #fff;
    background-image: linear-gradient(to right, #fff 0%, #000 100%);
    animation: progress 7.5s linear;
}

@keyframes progress {
    0% {
        width: 0%;
    }
    25% {
        width: 25%;
    }
    50% {
        width: 50%;
    }
    75% {
        width: 75%;
    }
    100% {
        width: 100%;
        background-color: #000;
    }
}

.progress-bar-text {
    position: absolute;
    top: 42px;
    right: 0;
    font-size: 10px;
    color: #d1d1d1;
}
