/* #Home {
    padding-bottom: 60px;
} */

.textAnimate {
    align-items: center;
    background-color: #191e24;
    border-radius: 3px;
    display: flex;
    gap: 5px;
    margin-top: 10px;
    padding: 0 10px;
}

.textAnimate svg {
    color: rgb(179, 179, 179);
}

.textAnimate .wrapper {
    overflow-x: hidden;
    width: 100%;
}

.textAnimate .wrapper .target {
    -webkit-animation-duration: 25s;
    animation-duration: 25s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: rightToLeft;
    animation-name: rightToLeft;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    color: #858e9b;
    line-height: 13px;
    margin: 5px 0;
    white-space: nowrap;
    font-size: 12px;
}

@keyframes rightToLeft {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(-100%);
    }
}

.ant-row.ant-row-space-around {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-action img {
    width: 26px;
    height: 26px;
}

.gObbco .main-action {
    display: flex;
    font-size: 11px;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    padding: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    color: white;
}

.ant-row.ant-row-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ant-col {
    margin-top: 12px;
    min-height: 1px;
    position: relative;
    width: 177px;
}

.gNkWet {
    width: 100%;
    height: 100%;
    background-color: rgb(42, 49, 60);
    border-radius: 6px;
    padding: 6px 0px;
    text-align: center;
    color: rgb(126, 133, 147);
}

.fhBEMg .banner-middle-1 {
    margin-top: 6px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.banner-middle-1 .banner-1 img {
    width: 177px;
    height: 68px;
}

.banner-middle-2 img {
    width: 360px;
    height: 68px;
}

.thu-thap-dai-ly {
    background-color: rgb(58, 67, 84);
    font-size: 18px;
    color: yellow;
    font-weight: 600;
    padding: 5px 0px;
    text-align: center;
    border-radius: 4px;
}

.thu-thap-dai-ly p {
    color: rgb(245, 197, 86);
    font-size: 16px;
}

.dNsaPg {
    display: flex;
    justify-content: space-around;
    background-color: rgb(42, 49, 60);
    margin: 6px 0px;
    border-radius: 4px;
    padding: 1px 0px;
    height: 30px;
}

.dNsaPg .dNsaPg-detail {
    color: rgb(126, 136, 150);
    display: flex;
    align-items: center;
    justify-content: center;
}

.dNsaPg-detail p {
    color: rgb(118, 126, 138);
    font-size: 15px;
    font-weight: bold;
}

.dNsaPg-detail span {
    color: rgb(230, 231, 234);
    font-weight: bold;
    font-size: 18px;
}

.box-home-shadow {
    width: 360px;
}
